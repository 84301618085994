@import 'global/const.scss';

.slider {

    position: relative;

    .butt-slider {

        cursor: pointer;
        width: 20px;
        height: 30px;
        position: absolute;
        top: calc(50% - 15px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

    }

    .butt-prev {

        left: 20px;
        background-image: url(/images/control/butt-prev.svg);

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            left: 10px;

        }

    }

    .butt-next {

        right: 20px;
        background-image: url(/images/control/butt-next.svg);

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            right: 10px;

        }

    }

    .pager {

        display: flex;
        position: absolute;
        font-size: 0;
        color: transparent;

        > div {

            width: 8px;
            height: 8px;
            flex-shrink: 0;
            margin: 0 3px;
            border-radius: 100%;
            background-color: rgba($main-bg, .5);

            &:last-of-type { margin-right: 0; }
            &.active { background-color: $main-bg; }

        }

    }

}

.slider-top {

    width: 100%;

    .butt-slider {

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            top: 135px;

        }

    }

    .pager {

        right: 20px;
        bottom: 20px;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            top: 272px;
            bottom: auto;

        }

    }

    .slider-link {

        display: flex;
        position: absolute;
        right: 20px;
        bottom: 48px;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            padding: 10px;
            position: static;

        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            display: block;

        }

        .g-button {

            margin-right: 30px;
            padding: 7px;
            display: block;
            color: $header-text;
            border: 2px solid transparent;
            background-color: $global-bg;

            /* Small only */
            @media screen and (max-width: 39.9375em) {

                margin-right: auto;

            }

            /* Medium only */
            @media screen and (min-width: 40em) and (max-width: 63.9375em) {

                margin: 5px auto;

            }

            &:hover { background-color: $color-7; }

        }

        .price-link {

            margin-right: 0;
            border-color: $butt-br;
            background-color: transparent;

            /* Small only */
            @media screen and (max-width: 39.9375em) {

                color: $path;
                border-color: $form-br;

            }

            &:hover { background-color: rgba($main-bg, .25); }

        }

    }

    .slide {

        width: 100%;
        height: 480px;
        vertical-align: top;
        position: relative;
        background-image: url(/images/background/slider.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            height: 300px;

        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            height: 400px;

        }

        &-descript {

            padding: 20px;
            padding-right: 370px;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 32px;
            font-weight: 600;
            white-space: normal;
            color: $header-text;
            background-image: linear-gradient(0deg, rgba($color-4, .75) 0%, transparent 100%);

            /* Small only */
            @media screen and (max-width: 39.9375em) {

                padding-right: 20px;
                padding-bottom: 48px;
                font-size: 18px;

            }

            /* Medium only */
            @media screen and (min-width: 40em) and (max-width: 63.9375em) {

                padding-right: 185px;
                font-size: 24px;

            }

        }

    }

}

.middle-block {

    padding: 30px 0;

    .services-wrapper {

        display: flex;
        flex-wrap: wrap;

    }

    .middle-title {

        padding: 30px;

        h1 {

            margin: 0;
            padding: 0;
            font-size: 30px;
            text-transform: uppercase;
            color: $main-text;

            /* Small only */
            @media screen and (max-width: 39.9375em) {

                font-size: 24px;
                text-align: center;

            }

        }

    }

    .service-block {

        flex-basis: 33%;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            flex-basis: 100%;

        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            flex-basis: 50%;

        }

        .service-image {

            width: 100%;
            height: 300px;
            position: relative;
            background-image: url(/images/background/service.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &:hover .service-link { display: flex; }

        }

        .service-link {

            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 24px;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            color: $header-text;
            background-color: rgba($header-bg, .75);

            &:before {

                content: '';
                width: 50px;
                height: 50px;
                margin-bottom: 50px;
                display: block;
                background-image: url(/images/control/Search.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

            }

        }

        .service-title {

            padding: 30px 10px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: $global-text;

        }

    }

}

.company-block {

    display: flex;
    flex-wrap: wrap;

    .slider-company {

        flex-basis: 50%;
        flex-shrink: 0;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            flex-basis: 100%;

        }

        .slide { width: 100%; }

        .slide-image {

            min-height: 450px;
            background-image: url(/images/background/slider-bottom.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            /* Small only */
            @media screen and (max-width: 39.9375em) {

                min-height: 300px;

            }

        }

        .pager {

            justify-content: center;
            right: 20px;
            bottom: 20px;
            left: 20px;

        }

    }

    .company-descript {

        padding: 40px;
        flex-basis: 50%;
        color: $global-text;
        box-sizing: border-box;
        background-color: $block-bg;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            padding: 20px;
            flex-basis: 100%;

        }

        .slide-title {

            padding-bottom: 30px;
            font-size: 30px;
            text-transform: uppercase;
            white-space: normal;

        }

        .slide-text {

            padding-bottom: 30px;
            font-size: 16px;
            white-space: normal;

        }

        .g-button {

            padding: 7px 30px;
            display: inline-block;
            color: $header-text;
            border: 2px solid transparent;
            background-color: $global-bg;

            &:hover { background-color: $color-7; }

        }

    }

}
